/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { LocalMenuModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { MenuMasterModelApiResult } from '../models';
/**
 * LocalApi - axios parameter creator
 * @export
 */
export const LocalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [menuId] 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalConfigurationGet: async (menuId?: string, bucketId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/local/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {string} [langCode] 
         * @param {boolean} [showHiddenMenu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalGet: async (bucketId?: string, url?: string, langCode?: string, showHiddenMenu?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (showHiddenMenu !== undefined) {
                localVarQueryParameter['showHiddenMenu'] = showHiddenMenu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalApi - functional programming interface
 * @export
 */
export const LocalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [menuId] 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalConfigurationGet(menuId?: string, bucketId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuMasterModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalConfigurationGet(menuId, bucketId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {string} [langCode] 
         * @param {boolean} [showHiddenMenu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalGet(bucketId?: string, url?: string, langCode?: string, showHiddenMenu?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalMenuModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalGet(bucketId, url, langCode, showHiddenMenu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalApi - factory interface
 * @export
 */
export const LocalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [menuId] 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalConfigurationGet(menuId?: string, bucketId?: string, langCode?: string, options?: any): AxiosPromise<MenuMasterModelApiResult> {
            return localVarFp.v1LocalConfigurationGet(menuId, bucketId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {string} [langCode] 
         * @param {boolean} [showHiddenMenu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalGet(bucketId?: string, url?: string, langCode?: string, showHiddenMenu?: boolean, options?: any): AxiosPromise<LocalMenuModelIEnumerableApiResult> {
            return localVarFp.v1LocalGet(bucketId, url, langCode, showHiddenMenu, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalApi - object-oriented interface
 * @export
 * @class LocalApi
 * @extends {BaseAPI}
 */
export class LocalApi extends BaseAPI {
    /**
     * 
     * @param {string} [menuId] 
     * @param {string} [bucketId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalApi
     */
    public v1LocalConfigurationGet(menuId?: string, bucketId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return LocalApiFp(this.configuration).v1LocalConfigurationGet(menuId, bucketId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketId] 
     * @param {string} [url] 
     * @param {string} [langCode] 
     * @param {boolean} [showHiddenMenu] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalApi
     */
    public v1LocalGet(bucketId?: string, url?: string, langCode?: string, showHiddenMenu?: boolean, options?: AxiosRequestConfig) {
        return LocalApiFp(this.configuration).v1LocalGet(bucketId, url, langCode, showHiddenMenu, options).then((request) => request(this.axios, this.basePath));
    }
}
