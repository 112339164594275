/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { Int32ApiResult } from '../models';
// @ts-ignore
import type { MenuModelApiResult } from '../models';
// @ts-ignore
import type { MobileMenuItemModelApiResult } from '../models';
/**
 * MenuApi - axios parameter creator
 * @export
 */
export const MenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 메뉴 권한 체크
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuCheckGet: async (bucketId?: string, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [staticUrl] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuGlobalGet: async (bucketId?: string, langCode?: string, staticUrl?: string, locationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (staticUrl !== undefined) {
                localVarQueryParameter['staticUrl'] = staticUrl;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [addinId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuImagesAddinPngGet: async (addinId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/images/addin.png`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addinId !== undefined) {
                localVarQueryParameter['addinId'] = addinId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [tenantId] 
         * @param {string} [bucketId] 
         * @param {string} [menuId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuImagesViewPngGet: async (tenantId?: string, bucketId?: string, menuId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/images/view.png`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuLnbGet: async (bucketId?: string, langCode?: string, locationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/lnb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuMobileGlobalGet: async (bucketId?: string, langCode?: string, locationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menu/mobile/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuApi - functional programming interface
 * @export
 */
export const MenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 메뉴 권한 체크
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuCheckGet(bucketId?: string, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuCheckGet(bucketId, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [staticUrl] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuGlobalGet(bucketId?: string, langCode?: string, staticUrl?: string, locationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuGlobalGet(bucketId, langCode, staticUrl, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [addinId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuImagesAddinPngGet(addinId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuImagesAddinPngGet(addinId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [tenantId] 
         * @param {string} [bucketId] 
         * @param {string} [menuId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuImagesViewPngGet(tenantId?: string, bucketId?: string, menuId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuImagesViewPngGet(tenantId, bucketId, menuId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuLnbGet(bucketId?: string, langCode?: string, locationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuLnbGet(bucketId, langCode, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MenuMobileGlobalGet(bucketId?: string, langCode?: string, locationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileMenuItemModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MenuMobileGlobalGet(bucketId, langCode, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuApi - factory interface
 * @export
 */
export const MenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuApiFp(configuration)
    return {
        /**
         * 
         * @summary 메뉴 권한 체크
         * @param {string} [bucketId] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuCheckGet(bucketId?: string, url?: string, options?: any): AxiosPromise<Int32ApiResult> {
            return localVarFp.v1MenuCheckGet(bucketId, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [staticUrl] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuGlobalGet(bucketId?: string, langCode?: string, staticUrl?: string, locationId?: string, options?: any): AxiosPromise<MenuModelApiResult> {
            return localVarFp.v1MenuGlobalGet(bucketId, langCode, staticUrl, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [addinId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuImagesAddinPngGet(addinId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1MenuImagesAddinPngGet(addinId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 이미지 조회
         * @param {string} [tenantId] 
         * @param {string} [bucketId] 
         * @param {string} [menuId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuImagesViewPngGet(tenantId?: string, bucketId?: string, menuId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1MenuImagesViewPngGet(tenantId, bucketId, menuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuLnbGet(bucketId?: string, langCode?: string, locationId?: string, options?: any): AxiosPromise<MenuModelApiResult> {
            return localVarFp.v1MenuLnbGet(bucketId, langCode, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {string} [langCode] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MenuMobileGlobalGet(bucketId?: string, langCode?: string, locationId?: string, options?: any): AxiosPromise<MobileMenuItemModelApiResult> {
            return localVarFp.v1MenuMobileGlobalGet(bucketId, langCode, locationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuApi - object-oriented interface
 * @export
 * @class MenuApi
 * @extends {BaseAPI}
 */
export class MenuApi extends BaseAPI {
    /**
     * 
     * @summary 메뉴 권한 체크
     * @param {string} [bucketId] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuCheckGet(bucketId?: string, url?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuCheckGet(bucketId, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketId] 
     * @param {string} [langCode] 
     * @param {string} [staticUrl] 
     * @param {string} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuGlobalGet(bucketId?: string, langCode?: string, staticUrl?: string, locationId?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuGlobalGet(bucketId, langCode, staticUrl, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 이미지 조회
     * @param {string} [addinId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuImagesAddinPngGet(addinId?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuImagesAddinPngGet(addinId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 이미지 조회
     * @param {string} [tenantId] 
     * @param {string} [bucketId] 
     * @param {string} [menuId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuImagesViewPngGet(tenantId?: string, bucketId?: string, menuId?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuImagesViewPngGet(tenantId, bucketId, menuId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketId] 
     * @param {string} [langCode] 
     * @param {string} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuLnbGet(bucketId?: string, langCode?: string, locationId?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuLnbGet(bucketId, langCode, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketId] 
     * @param {string} [langCode] 
     * @param {string} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public v1MenuMobileGlobalGet(bucketId?: string, langCode?: string, locationId?: string, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).v1MenuMobileGlobalGet(bucketId, langCode, locationId, options).then((request) => request(this.axios, this.basePath));
    }
}
